@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Roboto:wght@300;400&display=swap');
.CardWrapper {
    width: 30%;
    margin: 8% auto;
    text-align: center;
}
.CardWrapper p {
    display: inline;
}

.stock {
    color: #ff0606;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 2.3vmax;
    margin-bottom: 3%;
}
.discount {
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 2.3vmax;
    margin-bottom: 5%;
}
.monthPrice {
    color: #73ff81;
    font-size: 4vmax;
    font-weight: 700;
}
.wrapperPrice {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.monthCurrency {
    color: #73ff81;
    font-size: 1.5vmax;
    margin-top: 2%;
}
.Price {
    color: white;
    font-size: 4vmax;
    font-weight: 400;
    text-decoration: line-through;
}
.commonPrice p,
.discountPrice p {
    color: white;
    font-size: 1.5vmax;
}
.descr {
    color: white;
    font-size: 1.5vmax;
    text-align: start;
    margin: 10%;
}

.cardBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 5% auto 5%;
    height: 8vh;
    background: #d16eff;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.2vmax;
    font-weight: 700;
}

.active {
    display: none;
}

.PriceActive .discountPrice,
.PriceActive .commonPrice p {
    display: none;
}

.PriceActive .Price {
    color: #73ff81;
    font-size: 4vmax;
    font-weight: 700;
    text-decoration: none;
}

@media (max-width: 800px) {
    .CardWrapper {
        width: 60%;
    }

    .cardBtn {
        height: 5vh;

        font-size: 1.5vmax;
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .cardBtn {
        height: 6vh;
    }
}

@media (min-width: 2100px) {
    .CardWrapper {
        width: 25%;
    }

    .monthPrice {
        font-size: 2vmax;
    }

    .cardBtn {
        height: 5vh;

        font-size: 1vmax;
    }
}
