@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.SuccessTrajectory {
    width: 100%;
    height: 100vh;
    background: black;
    margin-top: 6.28%;
}

.titleWrapper {
    margin-left: 6vw;
    margin-right: 6vw;
    width: 87.5vw;
}

.title {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.2vmax;
    line-height: 140%;

    text-align: center;
    text-shadow: 0px 0px 10px #2692ec;
}

.slideTitle {
    color: white;
    margin-bottom: 2vh;
}
.subTitle {
    color: white;
    margin-top: 1.75vh;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.8vmax;
    line-height: 140%;
}

.splideWrapper {
    margin-top: 6.66vh;
}
.splideWrapper2 {
    margin-top: 5.26vh;
}
.splideWrapper2 img {
    width: 95vw;
}
.splide {
    width: 80vw;

    margin: 0 auto;
}
.chart {
    width: 90%;
    margin: -5% auto 0;
}

.characters img {
    width: 25.5vw;
    height: auto;
}

@media (min-width: 380px) {
    .theoryCourse {
        margin-top: 23.28%;
    }
}

@media (max-width: 450px) {
    .splide {
        height: 20vh;
    }

    .SuccessTrajectory {
        height: 75vh;
    }
}
@media (min-width: 600px) and (max-width: 800px) {
    .chart {
        margin: 0 auto 0;
    }
    .SuccessTrajectory {
        height: 75vh;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .SuccessTrajectory {
        margin-top: 30.28%;
    }

    .characters {
        height: 54vh !important;
    }

    .characters img {
        height: 50vh;
    }

    .table img {
        height: 75vh;
    }
    .SuccessTrajectory {
        height: 115vh;
    }
    .chart {
        margin: 0 auto 0;
    }
}
