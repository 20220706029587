.slider_transform.is-active .slider {
    transform: scale(1.1);
    transition: transform 0.3s;
}
.slider_transform.is-active .slider {
    box-shadow: 0px 0px 15px #d16eff;
    border: 1px solid black;
}
.slider_transform .slider {
    transform: scale(1);
    transition: transform 0.3s;
}

.slider_transform .slider {
    border: 1px solid #73ff81;
    box-shadow: 0px 0px 15px #73ff81;
}

.slider_transform .sliderBtn {
    background: #2692ec;
}
.slider_transform.is-active .sliderBtn {
    background: #ffb81a;
}
