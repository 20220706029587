.testIT {
    width: 100%;
    height: 100vh;
    margin-top: 6.28%;
}

.title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.21vmax;
    line-height: 140%;
    color: #ffffff;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 7vw;

    text-shadow: 0px 0px 10px #2692ec;
}

.subTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 7vw;
    font-size: 2.8vmax;
    line-height: 140%;
    margin-top: 10%;
    color: white;
}

.slider {
    margin: auto;
    margin-top: 3.56vh;
    width: 68.75vw;
}
.sliderImg {
    margin: 2.63vh 5vw 5vw 1.75vh;
}
.sliderImg img {
    width: 58.75vw;
}
.sliderTitle {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 3.15vmax;
    line-height: 140%;
    text-shadow: 0px 0px 10px #2692ec;
}
.splideItems li {
    width: 70vw !important;
}
.sliderBtn {
    width: 58.75vw;
    height: 5.96vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.45vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    color: #000000;
}

@media (min-width: 434px) {
    .title {
        margin-top: 8.28vh;
    }
}

@media (max-width: 450px) {
    .testIT {
        height: 84vh;
    }
}

@media (min-width: 495px) {
    .testIT {
        height: 110vh;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    .testIT {
        height: 90vh;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .sliderImg img {
        width: 20.75vw;
    }

    .sliderBtn {
        width: 30%;
        margin-left: 35%;
        margin-right: 35%;
    }
}
