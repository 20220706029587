.MvpConnectDesktop {
    background: url(./img/footer.svg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 30vh;
}
.MvpConnectDesktopWrapper {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding-top: 4vh;
}
.mvpImg {
    width: 15vw;
    margin: 0 auto;
}
.mvpImg img {
    width: 15vw;
}

.number {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8vmax;
    margin-top: 3vh;
    line-height: 140%;
    color: #ffb81a;
    text-align: center;
}
.itemButton {
    width: 50%;
}
.infoWrapper {
    display: flex;
    flex-direction: column;
}

.info {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 3% auto;
    font-size: 2vmin;
    line-height: 140%;
    color: #ffffff;
}

.buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;
    line-height: 140%;
}

.buttonWrapper a {
    width: 30%;
}

.buttonCourse {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    height: 4vh;
    background: #ffffff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: black;
}

.buttonPractise {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 50%;
    height: 4vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: black;
}

.jobWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.job {
    font-size: 1.5vmax;
    margin-top: 10%;
    text-align: center;
    color: #ffffff;
    text-decoration: underline;
    text-shadow: 0px 0px 15px #ffb81a;
}

.participant {
    margin-top: 10%;
    text-align: center;
    font-size: 1.5vmax;
    color: #ffffff;
    text-decoration: underline;
    text-shadow: 0px 0px 15px #ffffff;
}

@media (max-width: 1300px) {
    .info {
        font-size: 1.5vmin;
    }
}
@media (max-width: 900px) {
    .info {
        font-size: 1.7vmin;
    }
}

@media (max-height: 600px) {
    .MvpConnectDesktop {
        height: 58vh;
        margin-top: 5%;
    }
}

@media (min-width: 2100px) {
    .mvpImg img {
        width: 8vw;
    }

    .info {
        font-size: 1.5vmin;
    }

    .number {
        font-size: 1vmax;
    }

    .buttonWrapper a {
        width: 15%;
    }

    .job {
        font-size: 1vmax;
    }

    .participant {
        font-size: 1vmax;
    }
}
