@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.studentChoice {
    width: 100%;
    height: 130vh;

    margin-top: 10.28%;
}

.mainTitle {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 3.6vmin;
    line-height: 140%;

    margin: 0 auto 1vh;
    text-shadow: 0px 0px 25px #2692ec, 0px 0px 15px #2692ec;
}

.mainSubTitle {
    width: 55%;
    margin: 1% auto 0;
    height: 3px;
    padding-top: 1.75vh;

    border-top: 3px solid #2692ec;
}

.wrapperTable {
    width: 68%;
    margin: 3vh auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.cell1,
.cell2,
.cell4,
.cell5 {
    border-right: 2px solid rgba(255, 184, 26, 0.22);
}

.cell4,
.cell5,
.cell6 {
    border-top: 2px solid rgba(255, 184, 26, 0.22);
}
.imgWrapper img {
    transition: all 0.3s;
}
.imgWrapper img:hover {
    transform: scale(1.1);
}

.titleWrapper {
    margin: 1vh 2vw 2vh;
}
.title {
    color: #ffb81a;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4vmin;
    line-height: 140%;
    transition: all 0.3s;
}
.title:hover {
    text-shadow: 0px 0px 15px #ffb81a;
}

.subTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.8vmin;
    line-height: 140%;
    color: #ffffff;
    margin-top: 2vh;
}

.btn {
    width: 18.75vw;
    height: 4.96vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin: 10.26vh auto 0;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.45vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    color: #000000;
}

@media (max-width: 1500px) {
    .studentChoice {
        height: 150vh;
    }
}

@media (max-width: 1100px) {
    .btn {
        font-size: 1.7vmax;
    }

    .title {
        font-size: 2.1vmin;
    }

    .subTitle {
        font-size: 1.5vmin;
    }
}

@media (max-width: 850px) {
    .subTitle {
        font-size: 1.8vmin;
    }
}

@media (min-width: 2100px) {
    .btn {
        width: 13.75vw;

        font-size: 1vmax;
    }
}
