@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.theoryCourse {
    width: 100%;
    height: 100vh;
    background: black;
    margin-top: 12.28%;
}

.titleWrapper {
    margin-left: 6vw;
    margin-right: 6vw;
    width: 87.5vw;
}

.title {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.2vmax;
    line-height: 140%;

    text-align: center;
    text-shadow: 0px 0px 10px #2692ec;
}
.subTitle {
    color: white;
    margin-top: 1.75vh;
    font-family: 'Open Sans';
    font-style: normal;

    font-weight: 600;
    font-size: 2.8vmax;
    line-height: 140%;
}
.spliderTrack {
    height: 65vh;
}
.slider {
    margin: auto;
    margin-top: 5.26vh;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 15px #d16eff;
    border-radius: 4px;
    width: 68.75vw;
    height: 89%;
}
.sliderImg {
    margin: 2.63vh auto 1.75vh;
}
.sliderImg img {
    width: 58.75vw;
}
.sliderTitle {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 3.15vmax;
    line-height: 140%;
    text-shadow: 0px 0px 10px #2692ec;
    margin: 0 2%;
    width: 96%;
}

.sliderSubTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.9vmax;
    line-height: 130%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    color: white;
    margin-top: 1.75vh;
}

.sliderBtn {
    width: 58.75vw;
    height: 5.96vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-top: 2.63vh;
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.45vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    color: #000000;
}
/* mobile  */
@media (min-width: 610px) {
    .slider {
        height: 91%;
    }
    .spliderTrack {
        height: 68vh;
    }
}

@media (min-width: 727px) {
    .slider {
        height: 87%;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .sliderImg img {
        height: 40.75vh;
    }

    .spliderTrack {
        height: 95vh;
    }
    .theoryCourse {
        height: 130vh;
    }

    .sliderBtn {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
    }

    .slider {
        width: 55.75vw;
        height: 92%;
    }
}
