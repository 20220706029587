.dMenu {
    color: black;
    text-align: center;
    font-size: 1.5vmax;
}

.dWrapper {
    width: 40%;
    margin: 0 auto;
    background: #f6c350;
    display: flex;
    justify-content: space-around;
    align-items: center;

    border-radius: 20px;
}
.dWrapper div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 9vh;
    border-radius: 20px;
    cursor: pointer;
}

.dMenuActive {
    background: #0692ec;
    color: white;
    font-weight: 700;
}

.mMenu {
    color: white;
}
.mMenuWrapper {
    width: 80%;
    margin: 10% auto 10%;
}
.mMenuWrapper select {
    width: 100%;
    height: 8vh;
    background: #0692ec;
    color: white;
    padding-left: 5%;

    font-weight: 700;
    font-family: 'Roboto', Arial, sans-serif;
    border-radius: 10px;
}

@media (min-width: 2100px) {
    .dMenu {
        font-size: 1.2vmax;
    }

    .dWrapper {
        width: 30%;
    }
    .dWrapper div {
        height: 7vh;
    }
}
