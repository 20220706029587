.burgerMenu {
    height: 11.1vh;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    z-index: 50;
}
.mvpIcon {
    width: 22.18vw;
    height: 5.61vh;
    padding: 5vw;
    position: absolute;
}
.mvpIconImg {
    width: 22.18vw;
    height: 5.61vh;
}

.burgerIcon {
    margin: 4vh 5vw 4vh 86.56vw;
}

.fixed {
    position: fixed;
    height: 8.43vh;
}

.fixedImg {
    width: 15.62vw;
}
.fixedIcon {
    width: 22.18vw;
    height: 5.61vh;
    padding: 2.5vw;
}

.fixedBurgerIcon {
    margin: 3vh 5vw 3vh 86.56vw;
}

/* mobile  */
@media (min-width: 600px) {
    .mvpIcon {
        width: 22.18vw;
        height: 5.61vh;
        padding: 3vh 1vw 1vh 1vw;
        position: absolute;
    }
    .fixedImg {
        width: 15.62vw;
        height: 8.77vh;
    }
    .fixedIcon {
        padding: 1vh 1vw 1vh 1vw;
    }
}

/* mobile with rotate */

@media (max-height: 500px) {
    .burgerMenu {
        height: 17.1vh;
    }
    .burgerIcon {
        margin: 8vh 5vw 4.82vh 86.56vw;
    }
    .mvpIcon {
        padding: 3.5vw;
    }
    .mvpIconImg {
        height: 7.61vh;
    }
}
