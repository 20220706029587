@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

.TestIT {
    width: 100%;
    height: 100vh;
}

.title {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 3.8vmin;
    line-height: 140%;
    width: 60%;
    margin: 0 auto;

    text-shadow: 0px 0px 25px #2692ec, 0px 0px 15px #2692ec;
}
.subTitle {
    color: white;

    font-family: 'Open Sans';
    font-style: normal;
    width: 60%;
    margin: 0 auto;
    font-weight: 600;
    font-size: 3vmin;
    line-height: 140%;
}

.subTitle p {
    padding-top: 1.75vh;
    display: inline-block;
    border-top: 3px solid #2692ec;
}
.splideWrapper {
    width: 70%;
    margin: 0 15%;
}

.slider {
    margin: 0 auto;
    margin: 10.26vh auto 6.5vh;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 15px #ffb81a;
    border-radius: 4px;
    width: 10.75vw;
    height: 85%;
}
.sliderImg {
    margin: 2.63vh auto 1.75vh;
}
.sliderImg img {
    height: 10.75vw;
    transition: all 0.3s;
}
.sliderTitle {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.55vmax;
    line-height: 140%;
    text-shadow: 0px 0px 10px #2692ec;
    margin: 0 auto 35%;
    transition: all 0.3s;
}

.sliderSubTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1vmax;
    line-height: 130%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    color: white;
    margin-top: 1.75vh;
}

.sliderBtn {
    width: 14.75vw;
    height: 3.76vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin: 8.63vh auto 0;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.15vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    color: #000000;
}

.title1 {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4vmin;
    line-height: 140%;
}

.subtitle1 {
    width: 70%;
    margin: 2% 15% 0 15%;
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 100;
    font-size: 2.4vmin;
    line-height: 140%;
}

@media (max-width: 1500px) {
    .TestIT {
        height: 120vh;
    }
}

@media (max-width: 1100px) {
    .sliderBtn {
        font-size: 1.7vmax;
    }
}

@media (max-width: 900px) {
    .sliderBtn {
        font-size: 1.5vmax;
    }
}

@media (max-height: 600px) {
    .TestIT {
        margin-top: 10vh;
    }
}

@media (min-width: 2100px) {
    .sliderImg img {
        height: 5.75vw;
    }

    .sliderTitle {
        font-size: 1.2vmax;
    }

    .sliderBtn {
        width: 11.75vw;

        font-size: 1vmax;
    }
}
