@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.studentChoice {
    width: 100%;
    height: 123vh;
    background: black;
    margin-top: 10.28%;
}

.mainTitle {
    width: 90vw;
    height: 15.43vh;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.2vmax;
    line-height: 140%;
    color: #ffffff;
    text-shadow: 0px 0px 10px #2692ec;
    margin-left: 5vw;
    margin-right: 5vw;
}
.accordiondWrapper {
    display: flex;
    justify-content: center;
}

.accordion {
    margin-top: 5.26vh;
    margin-left: 5.31vw;
    margin-right: 4.46vw;
    width: 90vw;
}

.icon {
    float: left;
    padding-top: 3vh;
}

.title {
    color: white;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    cursor: pointer;
    color: #aeb4b9;
}

.title h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 2.8vmax;
    width: 59.6vw;
    font-weight: 400;
    text-align: start;
    transition: 0.4s;
}
.title:hover {
    color: gold;
}

.titleActive {
    color: gold;
}
.content {
    text-align: start;
    color: white;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.1vmax;

    margin-left: 15.56vw;
    margin-bottom: 1.22vh;
}

.activeContent {
    max-height: 150px;
    transition: all 0.5s;
}
.arrow {
    transition: all 0.5s;
}
.active {
    transform: rotate(180deg);
}

.btn {
    width: 58.75vw;
    height: 5.96vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-top: 5.26vh;
    margin-left: 20.62vw;
    margin-right: 20.62vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.45vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    color: #000000;
}

@media (max-width: 450px) {
    .studentChoice {
        height: 120vh;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    .studentChoice {
        height: 100vh;
    }
}
/* mobile with rotate */
@media (max-height: 500px) {
    .btn {
        width: 30%;
        margin-left: 35%;
        margin-right: 35%;
    }
    .studentChoice {
        height: 112vh;
    }
    .title h2 {
        font-size: 2.5vmax;
    }
    .accordion {
        margin-top: 7.26vh;
    }
}
@media (max-height: 552px) {
    .icon {
        padding-top: 0;
    }
}
