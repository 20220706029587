a {
    text-decoration: none;
}
a,
a:visited,
a:hover,
a:active {
    color: inherit;
}
.App {
    display: none;
}

.check {
    background: center url(./breakImg/break.jpg);

    height: 100vh;
    width: 100vw;
}
