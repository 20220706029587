.check__body {
    background-color: #000;
}

.check_header {
    font-weight: 600;
    font-size: 60px;
    color: #fff;
    text-align: center;
    padding-top: 120px;
}
.check__description {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    margin-top: 95px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
}
.check__input-title {
    font-weight: 600;
    font-size: 60px;
    color: #fff;
}
.check__input-border {
    width: 368px;
    height: 84px;
    border: 3px solid #fbf109;
    margin-left: 50px;
}
.check__input {
    width: 364px;
    border: none;
    outline: none;
    height: 82px;
    background-color: #000;
    font-size: 60px;
    font-weight: 600;
    color: #fff;
}
.check__input-container {
    display: flex;
    justify-content: center;
    margin-top: 134px;
}
.check__example-container {
    width: 62%;
}
.check__example-img {
    width: 62%;
    margin-left: 19%;
    margin-top: 34px;
}
.check__error {
    color: #ff2222;
    font-weight: 600;
    font-size: 18px;
    margin-top: 30px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
}
@media (max-width: 1400px) {
    .check_header {
        font-size: 40px;
    }
    .check__description {
        margin-top: 40px;
    }
    .check__input-container {
        margin-top: 40px;
    }
}
@media (max-width: 770px) {
    .check_header {
        font-size: 20px;
        width: 100%;
    }
    .check__description {
        font-size: 16px;
    }
    .check__input-title {
        font-size: 40px;
    }
    .check__input-border {
        width: 75%;
        margin-left: 10px;
        height: 50px;
    }
    .check__input {
        width: 75%;
        height: 48px;
        font-size: 38px;
    }
    .check__error {
        font-size: 16px;
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
    }
    .check__example-container {
        width: 90%;
    }
    .check__example-img {
        width: 90%;
        margin-left: 5%;
    }
}
