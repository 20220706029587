@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

.SuccessTrajectory {
    width: 100%;
    height: 100vh;
}

.title {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4vmin;
    line-height: 140%;

    text-shadow: 0px 0px 25px #2692ec, 0px 0px 15px #2692ec;
}
.subTitle {
    color: white;

    font-family: 'Open Sans';
    font-style: normal;
    width: 60%;
    margin: 0 auto;
    font-weight: 600;
    font-size: 3vmin;
    line-height: 140%;
}

.subTitle p {
    padding-top: 1.75vh;
    display: inline-block;
    border-top: 3px solid #2692ec;
}
.splideWrapper {
    width: 70%;
    margin: 0 15%;
}

.slider {
    margin: 0 auto;
    margin: 10.26vh auto 6.5vh;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 15px #ffb81a;
    border-radius: 4px;
    width: 10.75vw;
    height: 85%;
}

.sliderImg img {
    transition: all 0.3s;
}
.sliderImg {
    margin: 2.63vh auto 1.75vh;
}
.sliderImg img {
    height: 8.75vw;
}
.sliderTitle {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.55vmax;

    line-height: 140%;
    text-shadow: 0px 0px 10px #2692ec;
    margin: 0 auto 25%;
    transition: 0.3s;
}

.chart {
    width: 40%;
    margin: 3% auto;
}

@media (max-width: 1100px) {
    .SuccessTrajectory {
        height: 150vh;
    }
}

@media (max-height: 600px) {
    .SuccessTrajectory {
        height: 150vh;
    }
}

@media (min-width: 1400px) and (max-width: 1800px) {
    .chart canvas {
        height: 200px;
    }
    .chart {
        margin: 1% auto;
    }
}

@media (min-width: 2100px) {
    .sliderImg img {
        height: 5.75vw;
    }

    .sliderTitle {
        font-size: 1vmax;
    }
}
