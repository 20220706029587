@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.FeebackGraduatesDesktop {
    width: 100%;
    height: 120vh;
}

.title {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4vmin;
    line-height: 140%;

    text-shadow: 0px 0px 25px #2692ec, 0px 0px 15px #2692ec;
}
.subTitle {
    color: white;

    font-family: 'Open Sans';
    font-style: normal;
    width: 60%;
    margin: 0 auto;
    font-weight: 600;
    font-size: 3vmin;
    line-height: 140%;
}

.subTitle p {
    padding-top: 1.75vh;
    display: inline-block;
    border-top: 3px solid #2692ec;
}
.splideWrapper {
    width: 80%;
    margin: 0 10% 0;
}
.splideTrack {
    height: 72vh;
}
.slider {
    margin: 0 auto;
    margin-top: 15vh;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 15px #ffb81a;
    border-radius: 4px;
    width: 21.75vw;
    height: 66%;
    margin-bottom: 4.5vh;
}
.slider1 {
    margin: auto;

    width: 100%;
    height: 100%;
    position: relative;
}
.slider.active {
    height: 70%;
}

.sliderImg {
    margin: -10.9vh auto 0;
}

.sliderImg img {
    width: 11vw;
    border-radius: 50%;
    border: 1px solid #73ff81;
}

.linedin {
    color: white;
    float: right;
    height: fit-content;
    margin-right: 1vw;
    margin-top: -3.2vw;
}

.linedin a {
    color: white;
    text-decoration: none;
}

.sliderTitle {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.45vmax;
    line-height: 130%;
    width: 90%;
    margin: 3.28vh 5% 0;
}

.sliderProfession {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vmax;
    margin-top: 1.75vh;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.31);
}
.sliderProfession a {
    color: #ff8562;
}
.sliderStarsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.75vh;
}
.sliderNumberStars {
    color: #ffb81a;
    font-size: 1.7vmax;
    font-weight: 700;
}
.sliderStars {
    margin-left: 0.5%;
}
.sliderStars img {
    width: 70%;
}
.sliderSubTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 1vmax;
    line-height: 130%;
    color: white;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1.75vh;
    width: 80%;
    height: 15.22vh;
    overflow: hidden;
    position: absolute;
}

.sliderSubTitle.active {
    height: 18.22vh;
    overflow-y: scroll;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}

.sliderMore {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    width: 50%;
    position: absolute;
    margin: 15vh 0 0 8.5vw;
}

.sliderMore.active {
    display: none;
}
.moreGradient {
    width: 9.68vw;
    height: 2.98vh;
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.49343487394957986) 50%,
        rgba(0, 0, 0, 1) 100%
    );
}
.moreText {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vmax;
    line-height: 130%;
    height: 2.75vh;
    background: black;
    color: gold;
}

.starsWrapper {
    width: 100%;
    height: 100%;
}
.number {
    color: #ffb81a;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4vmax;
    margin-top: 10vh;
    line-height: 130%;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.numberDescr {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1vmax;
    line-height: 130%;
    width: 70%;
    margin: 10% 15% 10%;
}

.stars {
    display: flex;
    justify-content: center;
}
.stars div {
    height: 5vh;
}
.stars div img {
    height: 100%;
}
.star {
    margin-left: 2%;
}

/* Desktop  */
@media (max-width: 1500px) {
    .FeebackGraduatesDesktop {
        width: 100%;
        height: 130vh;
    }
    .stars div {
        height: 4vh;
    }
    .sliderImg {
        margin: -8.9vh auto 0;
    }
    .slider {
        height: 58%;
    }

    .slider.active {
        height: 63%;
    }
}
@media (max-width: 1200px) {
    .sliderSubTitle {
        font-size: 1.2vmax;
    }

    .slider {
        height: 60%;
    }
    .linedin {
        top: 22%;
    }
}
@media (max-width: 1050px) {
    .sliderImg {
        margin: -6.9vh auto 0;
    }
    .stars div {
        height: 2vh;
    }
}
@media (max-width: 1100px) {
    .sliderTitle {
        font-size: 1.5vmax;
    }

    .numberDescr {
        font-size: 1.3vmax;
    }
}
@media (max-width: 900px) {
    .sliderSubTitle {
        font-size: 1.4vmax;
    }
}
@media (max-width: 850px) {
    .sliderImg {
        margin: -6.9vh auto 0;
    }
    .sliderSubTitle {
        font-size: 1.4vmax;
    }
    .stars img {
        height: 2vh;
    }
}

/* tablet rotate */

@media (max-height: 965px) {
    .splideTrack {
        height: 84vh;
    }
    .stars div {
        height: 4vh;
    }
}

@media (max-height: 600px) {
    .splideTrack {
        height: 120vh;
    }
    .sliderImg {
        margin: -10.9vh auto 0;
    }

    .FeebackGraduatesDesktop {
        margin-bottom: 20vh;
    }

    .sliderImg img {
        width: 13vw;
    }

    .stars div {
        height: 5vh;
    }
}

@media (min-width: 2100px) {
    .sliderImg img {
        width: 9vw;
    }

    .sliderTitle {
        font-size: 1.3vmax;
    }

    .slider {
        width: 18.75vw;
    }
}
