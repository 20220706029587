.splide__track--nav > .splide__list > .splide__slide.is-active {
    border: none;
}
.splide__track--nav > .splide__list > .splide__slide {
    cursor: auto;
}

.splide__customArrow--prev {
    left: 0.5em;
}
.splide__customArrow--next {
    right: 0.5em;
}
.splide__customArrowDesktop--prev img {
    height: 20vh;
}
.splide__customArrowDesktop--prev {
    left: 3em;
    height: 20vh;
}
.splide__customArrowDesktop--next img {
    height: 20vh;
}
.splide__customArrowDesktop--next {
    right: 3em;
    height: 20vh;
}

.splide__customPagination {
    margin-top: 5.26vh;

    height: 2.98vh;
}

.splide__pagination__customPage {
    background: #d9d9d9a1;
    transform: scale(0.8);
}

.splide__pagination__customPage:hover {
    background: #ffb81a;
}

.splide__pagination__customPage.is-active {
    background: #ffb81a;
    transform: scale(0.8);
    z-index: 1;
}

@media (max-width: 1000px) {
    .splide__customArrowDesktop--next img {
        height: 10vh;
    }
    .splide__customArrowDesktop--prev img {
        height: 10vh;
    }
}
