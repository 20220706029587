.slider_transformTest.is-active img {
    transform: scale(1.3);
}
.slider_transformTest.is-active .titleTest {
    color: #ffb81a;
    text-shadow: 0px 0px 15px #ffb81a;
}

.slider_transformTest.is-active .sliderTest {
    border: none;
}
.slider_transformTest img {
    transform: scale(0.8);
}
.slider_transformTest.is-prev img {
    transform: scale(1.1);
}
.slider_transformTest.is-next img {
    transform: scale(1.1);
}

.slider_transformTest.is-active img {
    transform: scale(1.4);
}

.slider_transformTest .sliderTest {
    border: none;
    box-shadow: none;
}

.slider_transform .sliderBtn {
    background: #73ff81;
}
.slider_transform.is-active .sliderBtn {
    background: #d16eff;
}
