.burgerMenu {
    height: 11vh;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    z-index: 50;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 2vmin;
    line-height: 33px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    position: fixed;
    height: 9.43vh;
}

.mvpIconImg {
    height: 5.61vh;
}

.empty {
    flex-grow: 0.5;
}
.mvpIcon {
    width: 11.18vw;
    height: 5.61vh;
}

.home,
.itCourse,
.itPractise,
.cooperation,
.account {
    transition: 0.3s;
    cursor: pointer;
}

.home:hover,
.itCourse:hover,
.itPractise:hover,
.cooperation:hover,
.account:hover {
    text-shadow: 0px 0px 15px #ffb81a;
    color: #ffb81a;
}

@media (max-width: 1200px) {
    .burgerMenu {
        font-size: 1.7vmin;
    }
}

@media (max-width: 850px) {
    .burgerMenu {
        font-size: 1.8vmin;
    }
}
