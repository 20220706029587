.TestResults {
    width: 100%;
    height: 80vh;
}

.resultImg {
    margin-top: 20.52vh;
}
.resultImg img {
    width: 90vw;
}

.titleOverhead {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.45vmax;
    line-height: 130%;
    margin-top: 6.31vh;
    color: white;
}
.title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 3.5vmax;
    line-height: 130%;
    color: #f6c350;
    margin-top: 3.5vh;
}

.subTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.45vmax;
    line-height: 130%;
    margin-top: 3.5vh;
    color: white;
}

.resultBtn {
    width: 43.75vw;
    height: 5.96vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin: 5.54vh auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.45vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    color: #000000;
}

@media (max-width: 330px) {
    .TestResults {
        height: 90vh;
    }
}
@media (min-width: 375px) {
    .resultImg img {
        width: 80vw;
    }
}

@media (min-width: 420px) {
    .resultImg img {
        width: 70vw;
    }
}

@media (min-width: 480px) {
    .TestResults {
        height: 110vh;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .resultBtn {
        width: 30%;
        height: 6.4vh;
        margin-left: 35%;
        margin-right: 35%;
    }

    .resultImg img {
        height: 70vh;
        margin-top: 4vh;
    }
}

/* Desktop  */
@media (min-width: 700px) {
    .TestResults {
        height: 90vh;
    }
    .resultBtn {
        width: 14.75vw;
        height: 4.76vh;
        margin: 5.63vh auto 0;
        font-size: 1.4vmax;
    }

    .resultImg img {
        width: 25%;
        margin-top: 7vh;
    }

    .titleOverhead {
        font-size: 1.45vmax;
    }
    .title {
        font-size: 2.5vmax;
    }

    .subTitle {
        font-size: 1.45vmax;
    }
    .resultImg {
        margin-top: 1.52vh;
    }
}

@media (min-width: 2100px) {
    .resultImg img {
        width: 30vw;
    }

    .titleOverhead {
        font-size: 1vmax;
    }
    .title {
        font-size: 1.2vmax;
    }
    .resultImg img {
        height: 40vh;
    }

    .subTitle {
        font-size: 1vmax;
    }
    .resultBtn {
        width: 12.75vw;
        height: 3.76vh;
        margin: 8.63vh auto 0;
        font-size: 1vmax;
    }
}
