.Accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleWrapper {
    text-align: center;
    margin: 5% auto;
}
.title {
    font-size: 4vmax;
    color: rgb(246, 195, 80);
    font-weight: 700;
    font-family: 'Roboto', Arial, sans-serif;
}

.subTitle {
    margin-top: 8%;
    font-size: 2.2vmax;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-family: 'Roboto', Arial, sans-serif;
}

.subTitle p {
    font-size: 1.5vmax;
    font-weight: 300;
    margin-top: 4%;
    font-family: 'Roboto', Arial, sans-serif;
}

.accordionWrapper {
    color: white;
    font-size: 2vmax;
    width: 50%;
}
/* .accordionTopBorder {
    border-top: 2px solid white !important;
}
.accordionItem {
    border-top: 1px solid white;
}
.accordionItemBorder {
    border-bottom: 2px solid white;
    transition-delay: 0.23s;
} */

.accordionTitle {
    height: auto;
    display: flex;
    justify-content: space-between;
}
.accordionPlus {
    height: min-content;
    margin: auto 0;
    font-size: 4vmax;
    font-weight: 300;
    padding-bottom: 2%;
    transition: all 0.2s;
}

.accordionPlusActive {
    transform: rotate(45deg);
    color: gold;
}

.accordionContent {
    max-height: 0;
    transform: scaleY(0);
    padding-top: 1px;
    transition: 0.2s;
    font-weight: 300;
}

.accordionActive {
    max-height: max-content;
    transform: scaleY(1);
    padding-bottom: 3%;

    border-bottom: 2px solid white;
}

@media (max-width: 800px) {
    .title {
        margin: 10% auto;
        font-size: 2.5vmax;
    }
    .subTitle {
        margin: 10%;
    }
    .subTitle p {
        font-size: 2vmax;
    }

    .accordionWrapper {
        width: 80%;
    }
}

@media (min-width: 2100px) {
    .title {
        font-size: 2vmax;
    }

    .subTitle p {
        font-size: 1vmax;
    }
    .subTitle {
        font-size: 1.4vmax;
    }
    .accordionWrapper {
        font-size: 1.2vmax;
    }
    .accordionPlus {
        font-size: 2vmax;
    }
}
