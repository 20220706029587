.twoLine {
    width: 8.43vw;
    height: 2.8vh;
    position: relative;
    transition: all 0.3s;
}

.twoLine::before {
    content: '';
    right: 0;
    position: absolute;

    background: white;
    width: 8.43vw;
    height: 0.52vh;
}

.twoLineActive::before {
    visibility: hidden;
}

.twoLine::after {
    content: '';
    position: absolute;
    bottom: 0.2vh;
    width: 8.43vw;
    right: 0;
    background: white;
    height: 0.52vh;
}
.twoLineActive::after {
    visibility: hidden;
}

.mediumLine {
    position: absolute;
    top: 1vh;
    background: white;
    width: 8.43vw;
    height: 0.52vh;
    right: 0;
    transition: 0.3s;
}

.mediumLineActive {
    transform: rotate(45deg);
    transition: 0.3s;
}
.mediumLineActive2 {
    transform: rotate(-45deg);
    transition: 0.3s;
}

.fixedTwoLine {
    width: 7.05vw;
    height: 2.28vh;
    transition: all 0.3s;
    position: relative;
}

.fixedTwoLine::before {
    content: '';
    position: absolute;

    right: 0;
    background: white;
    width: 7.05vw;

    height: 0.35vh;
}

.fixedTwoLine::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0.2vh;
    width: 7.05vw;
    height: 0.35vh;
    background: white;
}

.fixedMediumLine {
    position: absolute;
    top: 0.87vh;
    right: 0;
    background: white;
    width: 7.05vw;
    height: 0.35vh;
    transition: 0.3s;
}

@media (min-width: 415px) {
    .mediumLine {
        width: 7.43vw;
    }

    .twoLine::before {
        width: 7.43vw;
    }

    .twoLine::after {
        width: 7.43vw;
    }
}

@media (min-width: 415px) {
    .fixedMediumLine {
        width: 6.43vw;
    }

    .fixedTwoLine::before {
        width: 6.43vw;
    }

    .fixedTwoLine::after {
        width: 6.43vw;
    }
}

@media (min-width: 475px) {
    .mediumLine {
        width: 6.43vw;
    }

    .twoLine::before {
        width: 6.43vw;
    }

    .twoLine::after {
        width: 6.43vw;
    }
}

@media (min-width: 415px) {
    .fixedMediumLine {
        width: 5.43vw;
    }

    .fixedTwoLine::before {
        width: 5.43vw;
    }

    .fixedTwoLine::after {
        width: 5.43vw;
    }
}

/* mobile with rotate */

@media (max-height: 500px) {
    .mediumLine {
        width: 5.43vw;
        height: 0.62vh;
        top: 0.87vh;
    }

    .twoLine::before {
        width: 5.43vw;
        height: 0.62vh;
        top: -1vh;
    }

    .twoLine::after {
        width: 5.43vw;
        height: 0.62vh;
        bottom: -0.8vh;
    }

    .fixedMediumLine {
        width: 5.43vw;
        height: 0.62vh;
        top: 0.67vh;
    }

    .fixedTwoLine::before {
        width: 5.43vw;
        height: 0.62vh;
        top: -1vh;
    }

    .fixedTwoLine::after {
        width: 5.43vw;
        height: 0.62vh;
        bottom: -0.8vh;
    }
}
