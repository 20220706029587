@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.HistorySuccessDesktop {
    width: 100%;
    height: 100vh;
}

.title {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4vmin;
    line-height: 140%;

    text-shadow: 0px 0px 25px #2692ec, 0px 0px 15px #2692ec;
}
.subTitle {
    color: white;

    font-family: 'Open Sans';
    font-style: normal;
    width: 60%;
    margin: 0 auto;
    font-weight: 600;
    font-size: 3vmin;
    line-height: 140%;
}

.splideTrack {
    height: 50vh;
}

.subTitle p {
    padding-top: 1.75vh;
    display: inline-block;
    border-top: 3px solid #2692ec;
}
.splideWrapper {
    width: 80%;
    margin: 0 10%;
}
.splideWrapper img {
    width: 25vw;
}

/* Desktop  */

@media (max-width: 1500px) {
    .HistorySuccessDesktop {
        width: 100%;
        height: 85vh;
    }
}

@media (max-height: 600px) {
    .HistorySuccessDesktop {
        margin-top: 30vh;
    }
}
