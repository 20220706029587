@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.FeebackGraduates {
    width: 100%;
    height: 100vh;
    background: black;
}

.titleWrapper {
    margin-top: 20.28%;
    margin-left: 6vw;
    margin-right: 6vw;
    width: 87.5vw;
}

.title {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.2vmax;
    line-height: 140%;

    text-align: center;
    text-shadow: 0px 0px 10px #2692ec;
}

.sliderWrapper {
    margin-top: 14vh;
}
.slider {
    margin: auto;
    margin-top: 14vh;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 15px #d16eff;
    border-radius: 4px;
    width: 68.75vw;
    height: 43.85vh;
    position: relative;
}
.slider.active {
    height: 48.85vh;
}

.sliderImg {
    margin: -7.9vh auto 0;
}

.sliderImg img {
    width: 30.6vw;
    border-radius: 50%;
}

.linedin {
    color: white;
    float: right;
    margin-right: 5vw;
    margin-top: 2.8vw;
}

.linedin a {
    color: white;
    text-decoration: none;
    position: absolute;
    top: 5%;
    right: 5%;
}

.sliderTitle {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.8vmax;
    line-height: 130%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
}

.sliderProfession {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vmax;
    margin-top: 1.75vh;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.31);
}

.sliderProfession a {
    color: #ff8562;
}
.sliderStarsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.75vh;
}
.sliderNumberStars {
    color: #ffb81a;
    font-size: 3vmax;
    font-weight: 700;
}
.sliderStars {
    width: 10%;
    margin-left: 2%;
}

.sliderStars img {
    width: 100%;
}

.sliderSubTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vmax;
    line-height: 130%;
    color: white;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 1.75vh;
    width: 56.75vw;
    height: 11.22vh;
    overflow: hidden;
}

.sliderSubTitle.active {
    height: 17.22vh;
    overflow: scroll;
}

.sliderMore {
    display: flex;
    justify-content: flex-end;

    width: 36vw;
    position: absolute;
    margin: -2.51vh 0 0 25.75vw;
}

.sliderMore.active {
    display: none;
}
.moreGradient {
    width: 9.68vw;
    height: 2.98vh;
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.49343487394957986) 50%,
        rgba(0, 0, 0, 1) 100%
    );
}
.moreText {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vmax;
    line-height: 130%;
    height: 2.75vh;
    background: black;
    color: gold;
}

@media (min-width: 370px) {
    .slider {
        height: 45.85vh;
    }

    .slider.active {
        height: 52.85vh;
    }
}
@media (min-width: 384px) {
    .slider {
        height: 73.6%;
    }

    .slider.active {
        height: 75.55%;
    }
}
@media (min-width: 390px) and (max-width: 440px) {
    .titleWrapper {
        margin-top: 33.28%;
    }
}
@media (max-width: 450px) {
    .FeebackGraduates {
        height: 84vh;
    }
}
@media (min-width: 500px) {
    .slider {
        height: 75%;
    }
}

@media (min-width: 550px) {
    .slider {
        height: 75%;
    }
    .slider.active {
        height: 78.85%;
    }
}

@media (min-width: 580px) {
    .slider {
        height: 76%;
    }
    .slider.active {
        height: 78.85%;
    }
    .FeebackGraduates {
        height: 103vh;
    }
}
@media (min-width: 600px) {
    .slider {
        height: 76%;
    }
    .slider.active {
        height: 78.85%;
    }
    .FeebackGraduates {
        height: 103vh;
    }
}
@media (min-width: 620px) {
    .slider {
        height: 76%;
    }
    .slider.active {
        height: 78.85%;
    }
    .FeebackGraduates {
        height: 103vh;
    }
    .SplideTrack {
        height: 69vh;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    .FeebackGraduates {
        height: 90vh;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .sliderImg {
        margin: -17.9vh auto 0;
    }
    .sliderImg img {
        width: 20vw;
    }

    .slider {
        width: 55.75vw;
        height: 73.85vh;
    }

    .sliderSubTitle {
        margin-left: 10%;
        margin-right: 10%;

        width: 80%;
    }

    .SplideTrack {
        padding-top: 10%;
        height: 100vh;
    }

    .FeebackGraduates {
        height: 130vh;
    }

    .sliderMore {
        margin: -6vh 0 0 14.75vw;
    }
    .moreText {
        height: 6.75vh;
    }
    .moreGradient {
        width: 9.68vw;
        height: 5.98vh;
    }
}
