.mainComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: auto;
    background: black;
}

@media (min-width: 801px) {
    .wrapper {
        background: url('./img/backgroundDesktop.png') no-repeat;
        background-size: cover;
    }
}
