@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.OurPartnersDesktop {
    display: none;
    width: 100%;
    height: 80vh;

    margin-top: 8.28%;
}

.title {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4vmin;
    line-height: 140%;

    text-shadow: 0px 0px 25px #2692ec, 0px 0px 15px #2692ec;
}

.subTitle {
    width: 35%;
    margin: 1% auto 0;
    height: 3px;
    padding-top: 1.75vh;

    border-top: 3px solid #2692ec;
}
.splideWrapper {
    width: 80%;
    margin: 5% auto 0;
}
.splideWrapper img {
    width: 60vw;
}

/* Desktop  */

@media (max-width: 1500px) {
    .OurPartnersDesktop {
        margin-top: 0;
    }
}
@media (max-width: 1050px) {
    .splideWrapper img {
        width: 80vw;
    }
}

@media (max-height: 600px) {
    .OurPartnersDesktop {
        margin-top: 15vh;
    }
}
