@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.mainPage {
    background: url(./img/BacgroundMainPage.svg) no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}
.link {
    display: block;
    width: 15.62vw;
    margin: 0 auto;
}

.title {
    padding-top: 22.8vh;
    margin-left: 5vw;
    margin-right: 5vw;
    width: 90vw;
    height: 6vh;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12.5vmin;
    line-height: 140%;
    text-shadow: 0px 0px 25px #2692ec, 0px 0px 15px #2692ec;
}

.subtitle {
    width: 90vw;
    height: 13vh;
    margin-top: 3.5vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 5.6vmin;
    line-height: 140%;
    color: #f5f5f5;
    text-shadow: 0px 0px 15px rgba(38, 146, 236, 0.75);
}

.course {
    width: 43.7vw;
    height: 6.1vh;
    margin-top: 70px;
    margin-left: 28.1vw;
    margin-right: 28.1vw;
    background: #ffffff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.37vmin;
    line-height: 130%;
    color: #000000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.practice {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43.75vw;
    height: 6.14vh;
    margin-top: 5.26vh;
    margin-left: 28.12vw;
    margin-right: 28.12vw;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.37vmin;
    line-height: 130%;
    color: #000000;
    text-align: center;
}

.down {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;

    margin-top: 16.31vh;
    font-size: 4.37vmin;
    line-height: 100%;
    color: white;
}
.arrow {
    margin-top: 1vh;
}

/* mobile without rotate  */

@media (min-width: 370px) {
    .subtitle {
        margin-top: 4.5vh;
    }
}

@media (min-width: 410px) {
    .subtitle {
        margin-top: 5.5vh;
    }

    .down {
        margin-top: 14.31vh;
    }
}

@media (min-width: 435px) {
    .subtitle {
        margin-top: 6.5vh;
    }
}

@media (min-width: 480px) {
    .down {
        margin-top: 11.31vh;
    }
}
@media (min-width: 600px) {
    .course,
    .practice {
        height: 5.1vh;
    }
    .course p,
    .practice p {
        padding-top: 0.5vh;
        font-size: 3.9vmin;
    }
    .title {
        font-size: 11vmin;
    }
    .subtitle {
        font-size: 4.6vmin;
        margin-top: 7.5vh;
    }
}

/* mobile with rotate */

@media (max-height: 500px) {
    .mainPage {
        height: 110vh;
    }

    .subtitle {
        width: 90vw;
        height: 13vh;
        margin-top: 9.5vh;
    }

    .course,
    .practice {
        width: 30%;
        margin-left: 35%;
        margin-right: 35%;
    }
}

/* Desktop */

@media (min-width: 800px) {
    .mainPage {
        background: center url(./img/BacgroundMainPageDesktop.svg) no-repeat;
        -webkit-background: center url(./img/BacgroundMainPageDesktop.svg)
            no-repeat;

        height: 100vh;
        width: 100%;
    }

    .title {
        padding-top: 22.8vh;
        margin-left: 5vw;
        margin-right: 5vw;
        width: 90vw;
        height: 6vh;
        font-size: 9.6vmin;
    }

    .subtitle {
        width: 70%;
        height: 13vh;
        margin-top: 7vh;
        margin-left: 15%;
        margin-right: 15%;

        font-size: 4.8vmin;
    }

    .course {
        width: 21vw;
        height: 5vh;
        margin: 20.2vh auto 0;

        font-weight: 700;
    }
    .course,
    .practice {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .course p,
    .practice p {
        padding-top: 0;
        font-size: 2.1vmin;
    }

    .practice {
        width: 21vw;
        height: 5vh;
        margin: 5.26vh auto 0;
    }
    .down {
        display: none;
    }
}

@media (max-width: 1200px) and (min-width: 800px) {
    .title {
        font-size: 7.6vmin;
    }

    .subtitle {
        font-size: 3.8vmin;
    }
    .course p {
        font-size: 2vmin;
    }
    .practice p {
        font-size: 1.8vmin;
    }
}

@media (min-width: 2100px) {
    .mainPage {
        background-size: cover;
    }

    .title {
        font-size: 6.6vmin;
    }

    .subtitle {
        font-size: 2.8vmin;
    }

    .course {
        width: 10vw;
        margin: 5vh auto 0;
    }

    .practice {
        width: 10vw;
    }
    .down {
        display: none;
    }
    .course p {
        font-size: 1.5vmin;
    }
    .practice p {
        font-size: 1.5vmin;
    }
}
