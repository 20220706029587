.mvpConnect {
    background: url(./img/footer.svg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 50vh;
    text-align: center;
    background-color: black;
}

.down {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;

    margin-top: 3.31vh;
    font-size: 4.37vmin;
    line-height: 100%;
    color: white;
}
.arrow {
    margin-bottom: 1vh;
}

.mvpImg img {
    width: 40vw;
}

.mvpImg {
    width: 40vw;
    margin: 7.5vh auto 0;
}

.info {
    margin-top: 4.56vh;
}

.number {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.1vmax;
    line-height: 140%;
    color: #ffb81a;
}

.mail {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.1vmax;
    line-height: 140%;
    color: #ffb81a;
    margin-top: 1vh;
}

.participant img {
    width: 39vw;
}

.linkWrapper {
    display: block;
    width: 15.62vw;
    margin: 0 auto;
}

@media screen and (min-width: 400px) {
    .mvpConnect {
        height: 56vh;
    }
}

@media (max-width: 450px) {
    .mvpConnect {
        height: 45vh;
    }
}

@media (min-width: 480px) {
    .mvpConnect {
        height: 60vh;
    }
}
@media (min-width: 550px) {
    .mvpConnect {
        height: 63vh;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .mvpConnect {
        margin-top: 18vh;
        height: 111vh;
    }
}
