.Courses {
    background: black;
    width: 100%;
    height: max-content;
    text-align: center;
}

.wrapperTitle {
    padding: 11vh 5vw 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    color: gold;
    font-size: 4vmax;
}

.subTitle {
    margin-top: 3vh;
    color: white;
    font-size: 2.8vmax;
}

.item {
    margin-top: 4vh;
}
.img img {
    width: 90vw;
}
.imgPy img {
    width: 60.2vw;
}

.itemTitle {
    margin-top: 3vh;
    color: #f6c350;
    font-weight: 700;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    font-family: 'Roboto', Arial, sans-serif;
    height: 6vh;
    font-weight: 700;
    background: #d16eff;
    border-radius: 4px;
    margin: 3vh auto 0;
}

.footer {
    margin-top: 25vh;
}

.info {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.number,
.mail {
    color: rgb(6, 146, 236);
    margin-top: 4vh;
    font-size: 3vmax;
}

.descr {
    margin-top: 4vh;
    margin-bottom: 4vh;
    color: white;
    font-size: 2.3vmax;
}

.socialNetworkIcons {
    margin-top: 4vh;
    margin: 3vh auto 0;
    display: flex;
    width: 20vw;

    justify-content: space-around;
}

.socialNetworkIcons a {
    width: 10.5vw;
    padding: 0 2.5vw;
}

@media (min-width: 800px) {
    .itemWrapper {
        margin-top: 5vh;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .img img {
        width: 20vw;
    }
    .imgPy img {
        width: 14.1vw;
    }
    .itemTitle {
        font-size: 1.5vmax;
    }

    .btn {
        font-size: 1.5vmax;
    }
    .subTitle {
        font-size: 2vmax;
    }
    .title {
        font-size: 3vmax;
    }
    .number,
    .mail {
        font-size: 1.4vmax;
    }

    .descr {
        font-size: 1.2vmax;
    }
    .imgWrapper img {
        width: 10vw;
    }
    .socialNetworkIcons img {
        width: 3.5vw;
    }
}

@media (min-width: 2100px) {
    .img img {
        width: 15vw;
    }
    .imgPy img {
        width: 10.6vw;
    }
    .itemTitle {
        font-size: 1.2vmax;
    }

    .btn {
        font-size: 1.2vmax;
    }
    .subTitle {
        font-size: 1.3vmax;
    }
    .title {
        font-size: 2vmax;
    }
    .number,
    .mail {
        font-size: 1vmax;
    }

    .descr {
        font-size: 1vmax;
    }
    .imgWrapper img {
        width: 8vw;
    }
    .socialNetworkIcons img {
        width: 2vw;
    }
}
