@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.theoryCourse {
    width: 100%;
    height: 120vh;

    margin-top: 4.28%;
}

.title {
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4vmin;
    line-height: 140%;

    text-shadow: 0px 0px 25px #2692ec, 0px 0px 15px #2692ec;
}
.subTitle {
    color: white;

    font-family: 'Open Sans';
    font-style: normal;
    width: 50%;
    margin: 0 auto 4vh;
    font-weight: 600;
    font-size: 3vmin;
    line-height: 140%;
}

.subTitle p {
    padding-top: 1.75vh;
    display: inline-block;
    border-top: 3px solid #2692ec;
}
.splideWrapper {
    width: 80%;
    margin: 0 10%;
}

.slider {
    margin: 0 auto;
    margin-top: 4.26vh;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 15px #ffb81a;
    border-radius: 4px;
    width: 21.75vw;
    height: 85%;
    margin-bottom: 4.5vh;
}
.sliderImg {
    margin: 2.63vh auto 1.75vh;
}
.sliderImg img {
    width: 16.75vw;
}
.sliderTitle {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.55vmax;
    line-height: 140%;
    text-shadow: 0px 0px 10px #2692ec;
    margin-left: 2.56vw;
    margin-right: 2.56vw;
}

.sliderSubTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1vmax;
    line-height: 130%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    color: white;
    margin-top: 1.75vh;
}

.sliderBtn {
    width: 14.75vw;
    height: 3.76vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin: 1.63vh auto 0;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.15vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    color: #000000;
}

@media (max-width: 1100px) {
    .slider {
        height: 83%;
    }
    .sliderSubTitle {
        font-size: 1.2vmax;
    }
    .sliderBtn {
        font-size: 1.3vmax;
    }

    .sliderTitle {
        font-size: 1.25vmax;
    }
}

@media (max-width: 900px) {
    .sliderSubTitle {
        font-size: 1.4vmax;
    }
}
@media (max-width: 950px) {
    .sliderTitle {
        font-size: 1.3vmax;
    }
}
@media (max-width: 850px) {
    .sliderTitle {
        font-size: 1.3vmax;
    }
    .sliderSubTitle {
        font-size: 1.3vmax;
    }
}

@media (max-height: 600px) {
    .SplideTrack {
        height: 84vh;
        padding-top: 5vh;
    }
    .theoryCourse {
        margin-bottom: 12.28%;
    }
    .theoryCourse {
        margin-top: 13.28%;
    }
}

@media (min-width: 2100px) {
    .slider {
        width: 15.75vw;
    }

    .sliderImg img {
        width: 12.75vw;
    }
    .sliderTitle {
        font-size: 1vmax;
    }

    .sliderSubTitle {
        font-size: 0.7vmax;
    }

    .sliderBtn {
        width: 10.75vw;

        font-size: 0.9vmax;
    }
}
