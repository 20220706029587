@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.OurPartners {
    display: none;
    width: 100%;
    height: 80vh;
    background: black;
    margin-top: 20.28%;
    margin-bottom: 12.28%;
}

.titleWrapper {
    margin-left: 6vw;
    margin-right: 6vw;
    width: 87.5vw;
}

.title {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.2vmax;
    line-height: 140%;

    text-align: center;
    text-shadow: 0px 0px 10px #2692ec;
}

.splideWrapper {
    margin-top: 3.5vh;
}
.splideWrapper img {
    width: 65vw;
}
.subTitle {
    color: white;
    margin-top: 1.75vh;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.8vmax;
    line-height: 140%;
}

.slider {
    margin: auto;
    margin-top: 5.26vh;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 15px rgba(255, 184, 26, 0.3);
    border-radius: 4px;
    width: 68.75vw;
    height: 55.08vh;
}
.sliderImg {
    margin: 2.63vh 5vw 5vw 1.75vh;
}
.sliderImg img {
    width: 58.75vw;
}
.sliderTitle {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 3.15vmax;
    line-height: 140%;
    text-shadow: 0px 0px 10px #2692ec;
}

.sliderSubTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vmax;
    line-height: 130%;
    color: white;
    margin-top: 1.75vh;
}

.sliderBtn {
    width: 58.75vw;
    height: 5.96vh;
    background: #ffb81a;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-top: 2.63vh;
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.45vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    color: #000000;
}

@media (min-width: 370px) {
    .splideWrapper img {
        width: 55vw;
    }
}

@media (min-width: 410px) {
    .splideWrapper img {
        width: 50vw;
    }
}

@media (min-width: 450px) {
    .splideWrapper img {
        width: 45vw;
    }
}
@media (min-width: 520px) {
    .splideWrapper img {
        width: 40vw;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .OurPartners {
        margin-top: 36.28%;
    }

    .splideWrapper img {
        height: 100vh;
    }
}

/* desktop  */
@media (min-width: 700px) {
    .OurPartners {
        background: none;
    }
}
