.PracticeForm {
    color: white;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    display: none;
}
.formWrapper {
    width: 100%;
    padding-top: 2%;
    margin: 0 auto;
    display: flex;
}
.formActive {
    display: block;
}
.cross {
    width: 4%;
    margin-left: 96%;
    padding-top: 4%;
    cursor: pointer;
}
.cross img {
    width: 100%;
}
.formItem {
    background: black;
    width: 50%;

    display: flex;
    padding: 1% 5%;
    margin: 0 auto;
    height: 50%;
    flex-direction: column;
    border: 1px solid #2692ec;
    box-shadow: 0px 0px 15px #2692ec;
    align-items: center;
    justify-content: space-evenly;
}

.overhead {
    font-size: 1.3vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1%;
}

.title {
    text-shadow: 0px 0px 10px #2692ec;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    font-size: 1.8vmax;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;

    align-self: flex-start;
    width: 100%;
    height: max-content;
}

.inputWrapper label {
    margin-top: 1%;
    color: #ffb81a;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 1.1vmax;
}

.inputWrapper {
    margin-top: 1%;
    text-align: start;
}

.inputWrapper input {
    width: 100%;
    background: black;
    border: 1px solid #1c6db1;
    color: white;
    height: 3vh;
    font-size: 1.2vmax;
}

.inputItem {
    margin-bottom: 1%;
}

.profWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
}

.profWrapper input[type='text'] {
    width: 80%;
    margin-left: 3%;
    background: black;
    color: white;
    border: none;
    border-bottom: 1px solid #ffffff;
    font-size: 1.2vmax;
}
.profTitle {
    color: #ffb81a;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 1.2vmax;
    text-align: start;
}
.profWrapper input {
    position: relative;
    height: 22px;
    width: 22px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
}
.profWrapper input::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    border: 2px solid dodgerblue;
}
.profWrapper input:checked:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: dodgerblue;
    transform: translate(-50%, -50%);
    visibility: visible;
}
.profItem {
    margin-top: 2%;
    display: flex;
    align-items: center;
}
.profWrapper label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    color: #ffffff;
    font-size: 1.2vmax;
    margin-left: 2%;
}

.footer {
    display: flex;
    flex-direction: column;
    margin: 1% auto;
    text-align: center;
}
.footer a {
    width: 40%;
    margin: 3% auto;
}

.footerInfo {
    line-height: 140%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.1vmax;
    margin-top: 1%;
}

.footerInfo a {
    display: inline-block;
    color: #2692ec;
    width: inherit;
    margin: 0;
}

.footerBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3% auto;
    width: 60%;
    height: 5vh;
    background: #ffb81a;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.3vmax;
    font-weight: 700;
    border-radius: 10px;
    text-align: center;
    color: black;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
}

.splideTrack {
    width: 80%;
    margin: 3% auto;
}
.splideWrapper {
    width: 100%;
}

.coursesTitle {
    text-shadow: 0px 0px 10px #2692ec;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    font-size: 2vmax;
    text-align: center;
}

.footerBtnDisabled {
    pointer-events: none;
}

.footerBtnDisabled div {
    cursor: default;
    color: #888;
    background: rgb(207, 198, 198);
}

@media (max-width: 800px) {
    .formItem {
        width: 90%;
    }
    .cross {
        width: 10%;
        margin-right: 1%;
    }
    .formWrapper {
        width: 80%;
    }
    .overhead {
        font-size: 1.7vmax;
    }
    .title {
        font-size: 2.5vmax;
    }

    .inputWrapper label {
        font-size: 1.7vmax;
    }
    .inputWrapper input {
        font-size: 1.7vmax;
    }
    .profTitle {
        font-size: 1.7vmax;
    }
    .profWrapper label {
        font-size: 1.7vmax;
    }

    .profWrapper input::before {
        width: 14px;
        height: 14px;
    }
    .profWrapper input:checked:after {
        width: 12px;
        height: 12px;
    }
    .profWrapper input[type='text'] {
        width: 60%;
        font-size: 2vmax;
    }

    .footerInfo {
        font-size: 1.6vmax;
    }

    .footer a {
        width: 60%;
    }

    .footerBtn {
        font-size: 2vmax;
    }

    .overhead,
    .inputItem,
    .footer,
    .profWrapper {
        margin-bottom: 2%;
    }

    .inputWrapper label,
    .inputWrapper,
    .profItem,
    .footerInfo,
    .footer {
        margin-top: 2%;
    }
    .footer a {
        margin: 4% auto;
    }
    .footerInfo a {
        display: inline-block;
        color: #2692ec;
        width: inherit;
        margin: 0;
    }

    .coursesTitle {
        font-size: 3vmax;
    }

    .splideTrack {
        width: 70%;
    }
}

@media (max-height: 500px) {
    .inputWrapper input {
        height: 9vh;
        font-size: 2vmax;
    }
    .overhead {
        font-size: 2vmax;
    }
    .title {
        font-size: 2.5vmax;
    }

    .inputWrapper label {
        font-size: 2vmax;
    }
    .inputWrapper input {
        font-size: 2vmax;
    }
    .profTitle {
        font-size: 2vmax;
    }
    .profWrapper label {
        font-size: 2vmax;
    }
    .footerInfo {
        font-size: 1.6vmax;
    }
    .footerBtn {
        font-size: 2vmax;
        height: 9vh;
    }
    .footer a {
        width: 60%;
    }
    .footerInfo a {
        display: inline-block;
        color: #2692ec;
        width: inherit;
        margin: 0;
    }
    .profWrapper input[type='text'] {
        font-size: 2vmax;
    }
    .coursesTitle {
        font-size: 3.5vmax;
    }
}

@media (min-width: 2100px) {
    .cross {
        width: 3%;
        margin-left: 95%;
        margin-bottom: 5%;
    }
    .cross img {
        width: 100%;
    }
    .inputWrapper input {
        font-size: 1vmax;
    }
    .overhead {
        font-size: 1vmax;
    }
    .title {
        font-size: 2vmax;
    }

    .inputWrapper label {
        font-size: 1vmax;
    }
    .inputWrapper input {
        font-size: 1vmax;
    }
    .profTitle {
        font-size: 1vmax;
    }
    .profWrapper label {
        font-size: 1vmax;
    }
    .footerInfo {
        font-size: 1vmax;
    }
    .footerBtn {
        font-size: 1vmax;
    }

    .profWrapper input[type='text'] {
        font-size: 1vmax;
    }
    .coursesTitle {
        font-size: 2vmax;
    }

    .footerBtn {
        width: 80%;
        height: 5vh;

        margin: 0 auto;
        font-size: 1vmax;
    }
}
