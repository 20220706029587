@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.historySuccess {
    width: 100%;
    height: 90vh;
    background: black;
    margin-top: 12.28%;
}

.titleWrapper {
    margin-left: 6vw;
    margin-right: 6vw;
    width: 87.5vw;
}

.title {
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 4.2vmax;
    line-height: 140%;

    text-align: center;
    text-shadow: 0px 0px 10px #2692ec;
}
.subTitle {
    color: white;
    margin-top: 1.75vh;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.8vmax;
    line-height: 140%;
}
.splideWrapper {
    margin-top: 5.26vh;
}
.splideWrapper img {
    width: 80%;
}
.slider {
    margin: auto;
    margin-top: 5.26vh;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 15px rgba(255, 184, 26, 0.3);
    border-radius: 4px;
    width: 68.75vw;
    height: 55.08vh;
}

@media (max-width: 450px) {
    .historySuccess {
        height: 80vh;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .historySuccess {
        height: 110vh;
    }
}

/* desktop  */
@media (min-width: 700px) {
    .historySuccess {
        background: none;
    }
}
