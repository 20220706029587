.splide__customFormArrow--prev {
    width: 3%;
    left: 0;
}
.splide__customFormArrow--prev {
    width: 3%;
    left: 0;
}
.splide__customFormArrow--prev {
    width: 3%;
    left: 0;
}

.splide__customFormArrow--prev img {
    width: 100%;
}

.splide__customFormArrow--next {
    width: 3%;
    right: 0;
}
.splide__customFormArrow--next img {
    width: 100%;
}
