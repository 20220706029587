.TestITStartCss {
    width: 100%;
    height: 90vh;
}
.btnBackWrapper {
    margin-top: 8vh;
}
.btnBack {
    color: white;
    display: flex;
    margin-left: 5vw;
}

.btnBack p {
    margin-left: 3.12vw;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3vmax;
    line-height: 130%;
}

.titleWrapper {
    margin-top: 5.5vh;
}

.title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 3.5vmax;
    line-height: 130%;
    color: #f6c350;
}

.subTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.63vmax;
    line-height: 130%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    color: white;
    margin-top: 1.75vh;
}
.questionWrapper {
    margin-top: 3.5vh;
}
.questionNumber {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.45vmax;
    line-height: 130%;
    color: gray;
}

.question {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    width: 80%;
    margin: 2% auto 0;
    font-size: 2.45vmax;
    line-height: 130%;
    color: white;
}

.options {
    width: 80%;
    color: white;
    margin: 5.96vh auto 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.option {
    margin-top: 1.75vh;
    display: flex;
    align-items: flex-start;
}
.option label {
    margin-left: 3.12vw;
}
.option input {
    align-self: center;
}

.option p {
    margin-left: 3.12vw;
}

.allQuestionNumbers {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.45vmax;
    line-height: 130%;
    margin-top: 4.28vh;
    color: white;
}

.testBtn {
    width: 43.75vw;
    height: 5.96vh;
    background: #d16eff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    cursor: pointer;
    margin: 1.75vh auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.45vmax;
    line-height: 130%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    color: #000000;
}

.testBtnDisabled {
    pointer-events: none;
}

.testBtnDisabled div {
    cursor: default;
    color: #888;
    background: rgb(207, 198, 198);
}

@media (max-width: 330px) {
    .TestITStartCss {
        height: 110vh;
    }
}

/* mobile with rotate */
@media (max-height: 500px) {
    .testBtn {
        width: 30%;
        height: 6.4vh;
        margin-left: 35%;
        margin-right: 35%;
    }
    .TestITStartCss {
        height: 100vh;
    }
    .options {
        font-size: 2vmax !important;
    }
    .question {
        font-size: 2vmax !important;
    }
}

/* Desktop */
@media (min-width: 700px) {
    .testBtn {
        width: 14.75vw;
        height: 3.76vh;
        margin: 5.63vh auto 0;
        font-size: 1.4vmax;
    }

    .title {
        font-size: 2.5vmax;
    }

    .subTitle {
        font-size: 1.63vmax;
    }

    .questionNumber {
        font-size: 1.45vmax;
    }

    .question {
        font-size: 1.45vmax;
    }
    .allQuestionNumbers {
        font-size: 1.45vmax;
    }
    .options {
        font-size: 1.5vmax;
    }
    .option input {
        width: 25px;
        height: 25px;
    }
}

@media (min-width: 2100px) {
    .btnBack p {
        font-size: 1vmax;
    }

    .title {
        font-size: 2vmax;
    }

    .subTitle {
        font-size: 1.4vmax;
    }

    .questionNumber {
        font-size: 1.5vmax;
    }

    .question {
        font-size: 1.2vmax;
    }

    .options {
        font-size: 1vmax !important;
    }
    .allQuestionNumbers {
        font-size: 1.45vmax;
    }
    .testBtn {
        width: 10.75vw;
        height: 3.96vh;

        font-size: 1vmax;
    }
}
